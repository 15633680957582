<template>
  <div>
    <div class="page-title">
      <div class="row">
        <div class="col-xs-12 col-sm-4 col-md-6 col-lg-6">
          <h3>Lead Dashboard</h3>
          <div class="page-breadcrumb">
            <ol class="breadcrumb">
              <li>
                <router-link
                  :to="{name: 'home'}"
                >
                  Home
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{name: 'dashboard'}"
                >
                  Dashboard
                </router-link>
              </li>
              <li class="active">
                Leads
              </li>
            </ol>
          </div>
        </div>
        <div
          v-if="jobType !== 'User'"
          class="float-right m-t-md"
        >
          <button
            v-show="autoPlayStats"
            class="btn"
            style="background-color: #e9edf2"
            @click.stop.prevent="autoPlayStatsToggle()"
          >
            <span class="menu-icon fas fa-pause" />
          </button>
          <button
            v-show="!autoPlayStats"
            class="btn"
            style="background-color: #e9edf2"
            @click.stop.prevent="autoPlayStatsToggle()"
          >
            <span class="menu-icon fas fa-play" />
          </button>
        </div>
        <div
          v-if="jobType !== 'User'"
          class="col-xs-6 col-sm-4 col-md-3 col-lg-2 m-b-n-md float-right"
        >
          <label class="has-float-label">
            <VueMultiselect
              v-model="activeFilterOptions"
              :allow-empty="true"
              :limit="1"
              :multiple="true"
              :options="_getFilterOptions"
              :placeholder="null"
              :show-labels="false"
              label="name"
              track-by="id"
            />
            <span class="float-label">{{ activeFilterType + 's' }}: </span>
          </label>
        </div>
        <div
          v-if="jobType !== 'User'"
          class="col-xs-4 col-sm-3 col-md-2 col-lg-2 m-b-n-md float-right"
        >
          <label class="has-float-label">
            <VueMultiselect
              v-model="activeFilterType"
              :allow-empty="false"
              :multiple="false"
              :options="_getFilterTypes"
              :placeholder="null"
              :show-labels="false"
            />
            <span class="float-label">Type: </span>
          </label>
        </div>
      </div>
    </div>
    <div id="main-wrapper">
      <div class="row m-b-lg">
        <h1 class="text-center">
          <vue-typer
            :repeat="0"
            :text="getSalutation || ' '"
            caret-animation="smooth"
          />
        </h1>
      </div>
      <div class="row">
        <div class="col-xs-offset-0 col-xs-6 col-sm-offset-1 col-sm-5">
          <div class="panel info-box panel-white">
            <div class="panel-body">
              <div class="info-box-stats">
                <p class="counter">
                  {{ getStats('ASSIGNED') }}
                </p>
                <span class="info-box-title">Total Calls Assigned</span>
              </div>
              <div class="info-box-icon">
                <i class="icon-action-undo" />
              </div>
              <div class="info-box-progress">
                <div class="progress progress-xs progress-squared bs-n">
                  <div
                    :aria-valuenow="(Math.round(((getStats('ASSIGNED') / (_getLeads.length)) || 0) * 100))"
                    :style="{width: (Math.round(((getStats('ASSIGNED') / (_getLeads.length)) || 0) * 100)) + '%'}"
                    aria-valuemax="100"
                    aria-valuemin="0"
                    class="progress-bar progress-bar-danger"
                    role="progressbar"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-6 col-sm-5">
          <div class="panel info-box panel-white">
            <div class="panel-body">
              <div class="info-box-stats">
                <p class="counter">
                  {{ getStats('CALLED') }}
                </p>
                <span class="info-box-title">Total Calls Made</span>
              </div>
              <div class="info-box-icon">
                <i class="icon-user" />
              </div>
              <div class="info-box-progress">
                <div class="progress progress-xs progress-squared bs-n">
                  <div
                    :aria-valuenow="(Math.round(((getStats('CALLED') / (_getLeads.length)) || 0) * 100))"
                    :style="{width: (Math.round(((getStats('CALLED') / (_getLeads.length)) || 0) * 100)) + '%'}"
                    aria-valuemax="100"
                    aria-valuemin="0"
                    class="progress-bar progress-bar-info"
                    role="progressbar"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><!-- Row -->
      <div class="row">
        <div
          v-for="(category) in _getLeadFeedbackCategories"
          :key="category"
          class="col-xs-6 col-sm-4 col-md-4 col-lg-2"
        >
          <div class="panel info-box panel-white">
            <div class="panel-body">
              <div class="info-box-stats">
                <p class="counter">
                  {{ (Math.round(((getStats(category) / getStats('CALLED')) || 0) * 100)) + '%' }}
                  <span
                    class="text-muted"
                    style="font-size: 14px"
                  >({{ getStats(category) }})</span>
                </p>
                <span class="info-box-title">{{ category }}</span>
              </div>
              <div class="info-box-icon">
                <i class="icon-action-undo" />
              </div>
              <div class="info-box-progress">
                <div class="progress progress-xs progress-squared bs-n">
                  <div
                    :aria-valuenow="(Math.round(((getStats(category) / getStats('CALLED')) || 0) * 100))"
                    :style="{width: (Math.round(((getStats(category) / getStats('CALLED')) || 0) * 100)) + '%'}"
                    aria-valuemax="100"
                    aria-valuemin="0"
                    class="progress-bar progress-bar-danger"
                    role="progressbar"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><!-- Row -->
      <div
        v-if="jobType !== 'User'"
        class="row"
      >
        <div class="col-xs-12">
          <div class="panel panel-white">
            <div class="panel-heading">
              <h3 class="panel-title">
                Team Breakdown
              </h3>
            </div>
            <div class="panel-body">
              <div class="table-responsive">
                <table
                  id="teamStatisticsList"
                  class="table table-striped table-bordered"
                  style="width:100%"
                >
                  <thead>
                    <tr>
                      <th class="name">
                        Name
                      </th>
                      <th class="total">
                        Total
                      </th>
                      <th
                        v-for="(feedback) in _getLeadFeedbacks"
                        :id="feedback.id"
                        :key="feedback.id"
                        class="feedback-column"
                      >
                        {{ feedback.name }}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div><!-- Row -->
      <div
        v-if="jobType !== 'User'"
        class="row"
      >
        <div class="col-xs-12">
          <div class="panel panel-white">
            <div class="panel-heading">
              <h3 class="panel-title">
                Team Comparison
              </h3>
            </div>
            <div class="panel-body">
              <bar-chart
                :chart-data="teamChartData"
                :options="teamChartOptions"
              />
            </div>
          </div>
        </div>
      </div><!-- Row -->
      <div
        v-show="enableHourlyChart"
        class="row"
      >
        <div class="col-xs-12">
          <div class="panel panel-white">
            <div class="panel-heading">
              <h3 class="panel-title">
                Hourly Comparison
              </h3>
            </div>
            <div class="panel-body">
              <bar-chart
                :chart-data="hourlyChartData"
                :options="hourlyChartOptions"
              />
            </div>
          </div>
        </div>
      </div><!-- Row -->
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import VueMultiselect from "vue-multiselect";
import {VueTyper} from "vue-typer";
import BarChart from '../../components/BarChart';
import {barChartColours, leadFeedbackCategories} from "@/constants";

export default {
  name: "LeadDashboard",
  components: {
    BarChart,
    VueMultiselect,
    VueTyper
  },
  data() {
    return {
      jobType: '',
      activeFilterType: 'Product',
      activeFilterOptions: [],
      dashboardLeads: [],
      teamChartData: {
        labels: [],
        datasets: []
      },
      teamChartOptions: {},
      hourlyChartData: {
        labels: [],
        datasets: []
      },
      hourlyChartOptions: {},
      enableHourlyChart: false,
      teamTableData: [],
      teamTable: null,
      feedbackColours: {},
      autoPlayStats: true,
      pausedNewLeads: []
    }
  },
  computed: {
    ...mapGetters([
      "getLoggedInUser",
      "getUsers",
      "getTitles",
      "getEnabledSources",
      "getAllLeads",
      "getLeadFeedbacks",
      "getAllCampaigns",
      "getProducts"
    ]),
    ...mapState([
      'loggedInUser',
      'allLeads',
      'enabledSources'
    ]),
    _getLeadFeedbackCategories() {
      return _.values(leadFeedbackCategories);
    },
    _getLeadFeedbacks: function () {
      return _.sortBy(this.getLeadFeedbacks, 'name');
    },
    _getFilterTypes: function () {
      return ['Campaign', 'Product', 'Source'];
    },
    _getFilterOptions: function () {
      if (this.activeFilterType === 'Source') {
        let leadSources = _.compact(_.map(this.dashboardLeads, (leadLog) => {
          return leadLog && leadLog.lead ? leadLog.lead.source : null;
        }));
        return _.sortBy(_.filter(this.getEnabledSources, (source) => {
          return _.includes(leadSources, source.id);
        }), 'name');
      } else if (this.activeFilterType === 'Product') {
        let leadProducts = _.compact(_.map(this.dashboardLeads, (leadLog) => {
          return leadLog && leadLog.lead ? leadLog.lead.product : null;
        }));
        return _.sortBy(_.filter(this.getProducts, (product) => {
          return _.includes(leadProducts, product.id);
        }), 'name');
      } else if (this.activeFilterType === 'Campaign') {
        let leadSources = _.compact(_.map(this.dashboardLeads, (leadLog) => {
          return leadLog && leadLog.lead ? leadLog.lead.source : null;
        }));
        return _.sortBy(_.filter(this.getAllCampaigns, (campaign) => {
          return _.some(campaign.sources, (source) => {
            return _.includes(leadSources, source);
          });
        }), 'name');
      } else {
        return [];
      }
    },
    getSalutation() {
      if (this.getLoggedInUser && this.getLoggedInUser.birthDate && moment().format('MM-DD') === moment(this.getLoggedInUser.birthDate, 'YYYY-MM-DD').format('MM-DD')) {
        return 'Happy Birthday ' + (this.getLoggedInUser ? this.getLoggedInUser.firstName : '') + '!';
      }
      let salutation = 'Good Morning, ' + (this.getLoggedInUser ? this.getLoggedInUser.firstName : '') + ". Here is today's activity!";
      if (parseFloat(moment().format('HH')) >= 17) {
        salutation = 'Good Evening, ' + (this.getLoggedInUser ? this.getLoggedInUser.firstName : '') + ". Here is today's activity!";
      } else if (parseFloat(moment().format('HH')) >= 12) {
        salutation = 'Good Afternoon, ' + (this.getLoggedInUser ? this.getLoggedInUser.firstName : '') + ". Here is today's activity!";
      }
      return salutation;
    },
    _getLeads() {
      if (this.activeFilterType === 'Source' && this.activeFilterOptions && this.activeFilterOptions.length > 0) {
        let sources = _.map(this.activeFilterOptions, 'id');
        return _.filter(this.dashboardLeads, (lead) => {
          return lead && lead.lead && _.includes(sources, lead.lead.source);
        });
      } else if (this.activeFilterType === 'Product' && this.activeFilterOptions && this.activeFilterOptions.length > 0) {
        let products = _.map(this.activeFilterOptions, 'id');
        return _.filter(this.dashboardLeads, (lead) => {
          return lead && lead.lead && _.includes(products, lead.lead.product);
        });
      } else if (this.activeFilterType === 'Campaign' && this.activeFilterOptions && this.activeFilterOptions.length > 0) {
        let sources = _.flattenDeep(_.map(this.activeFilterOptions, 'sources'));
        return _.filter(this.dashboardLeads, (lead) => {
          return lead && lead.lead && _.includes(sources, lead.lead.source);
        });
      } else {
        return this.dashboardLeads;
      }
    },
    getStats() {
      return (groupType) => {
        let count = 0;
        let groupByAction = _.groupBy(this._getLeads, 'actionType');
        if (groupType === 'ASSIGNED') {
          let groupLeads = groupByAction[groupType];
          if (groupLeads && groupLeads.length > 0) {
            count = groupLeads.length;
          }
        } else if (groupType === 'CALLED') {
          _.forEach(groupByAction, (group, actionType) => {
            if (actionType !== 'ASSIGNED') {
              count += group.length;
            }
          });
        } else if (_.includes(_.values(leadFeedbackCategories), groupType)) {
          let categoryFeedbacks = _.map(_.filter(this.getLeadFeedbacks, (feedback) => {
            return feedback.category === groupType;
          }), 'id');
          _.forEach(groupByAction, (group, actionType) => {
            if (_.includes(categoryFeedbacks, actionType)) {
              count += group.length;
            }
          })
        }
        return count;
      }
    }
  },
  watch: {
    loggedInUser(newValue) {
      if (newValue && newValue !== '') {
        if (newValue.jobTitle && newValue.jobTitle !== '') {
          let jobTitle = _.find(this.getTitles, (title) => {
            return title.id === newValue.jobTitle;
          });
          if (jobTitle) {
            this.jobType = jobTitle.access;
          }
        }
      }
    },
    allLeads(newValue) {
      if (this.autoPlayStats) {
        let newLeads = _.differenceBy(newValue, this.dashboardLeads, 'id');
        if (newLeads.length > 0) {
          this.dashboardLeads = _.concat(this.dashboardLeads, newLeads);
          this.updateTeamTableData();
        }
      } else {
        let newLeads = _.differenceBy(newValue, this.pausedNewLeads, 'id');
        if (newLeads.length > 0) {
          this.pausedNewLeads = _.concat(this.pausedNewLeads, newLeads);
        }
      }
    },
    activeFilterType() {
      this.activeFilterOptions = [];
    },
    activeFilterOptions() {
      this.updateTeamTableData();
    }
  },
  mounted() {
    $('#sidebar').removeClass('visible');
    $('.page-inner').removeClass('sidebar-visible');

    if (this.getLoggedInUser && this.getLoggedInUser !== '') {
      if (this.getLoggedInUser.jobTitle && this.getLoggedInUser.jobTitle !== '') {
        let jobTitle = _.find(this.getTitles, (title) => {
          return title.id === this.getLoggedInUser.jobTitle;
        });
        if (jobTitle) {
          this.jobType = jobTitle.access;
        }
      }
    }

    let colourIndex = Math.floor(Math.random() * (barChartColours.length + 1));
    let colourIndexStep = Math.floor(barChartColours.length / _.size(this.getLeadFeedbacks));
    if (colourIndexStep === 0) {
      colourIndexStep = 1;
    }
    _.forEach(this.getLeadFeedbacks, (feedback) => {
      this.feedbackColours[feedback.id] = {
        borderColor: barChartColours[colourIndex % barChartColours.length].border,
        backgroundColor: barChartColours[colourIndex % barChartColours.length].color
      };
      colourIndex = colourIndex + colourIndexStep;
    });
    this.dashboardLeads = this.getAllLeads;
    if (this.jobType !== 'User') {
      this.createTeamTable();
    }
    this.updateTeamTableData();
  },
  methods: {
    prettyPrint: function (value, type) {
      if (type === 'User') {
        let user = _.find(this.getUsers, (user) => {
          return user.username === value;
        });
        if (user) {
          return _.upperCase(user.firstName + ' ' + user.lastName);
        } else {
          return value;
        }
      }
    },
    autoPlayStatsToggle() {
      if (!this.autoPlayStats) {
        let newLeads = _.differenceBy(this.pausedNewLeads, this.dashboardLeads, 'id');
        if (newLeads.length > 0) {
          this.dashboardLeads = _.concat(this.dashboardLeads, newLeads);
          this.updateTeamTableData();
        }
        this.pausedNewLeads = [];
      }
      this.autoPlayStats = !this.autoPlayStats;
    },
    createTeamTable: function () {
      this.teamTable = $('#teamStatisticsList').DataTable({
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Bfrtip',
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: this.teamTableData,
        rowId: 'name',
        columns: [
          {
            name: 'owner',
            data: 'name'
          },
          {
            name: 'total',
            data: 'total',
            defaultContent: ""
          },
          ..._.map(this._getLeadFeedbacks, (feedback) => {
            return {
              name: feedback.id,
              data: feedback.id,
              defaultContent: ""
            }
          })
        ],
        columnDefs: [
          {
            render: (data) => {
              return this.prettyPrint(data, 'User');
            },
            targets: [0]
          }
        ],
        buttons: [
          {
            extend: 'pageLength',
            text: '<i class="fas fa-filter"></i>',
            titleAttr: 'Number of Rows'
          },
          {
            extend: 'excelHtml5',
            autoFilter: true,
            text: '<i class="far fa-file-excel"></i>',
            titleAttr: 'Excel'
          }
        ],
        search: {
          regex: true,
          smart: false
        },
        select: {
          style: 'os',
          items: 'cell'
        },
        order: [[0, "asc"]]
      });

      this.teamTable.buttons()
          .container()
          .appendTo('#teamStatisticsList_wrapper .col-sm-6:eq(0)');
      this.teamTable.on('select', this.teamTableCellClick);
      this.teamTable.on('deselect', this.teamTableCellClick);
    },
    async teamTableCellClick() {
      $('.team-active-row').removeClass('team-active-row');
      $('.feedback-active-column').removeClass('feedback-active-column');
      this.teamTable.rows(_.map(this.teamTable.cells({selected: true})[0], 'row'))
          .nodes()
          .to$()
          .addClass('team-active-row');
      this.teamTable.columns(_.map(this.teamTable.cells({selected: true})[0], 'column'))
          .header()
          .to$()
          .not('.name, .total')
          .addClass('feedback-active-column');
      this.updateTeamChartData();
      this.updateHourlyChartData();
    },
    getTableLeadStats: function (leads) {
      let initTableData = [];
      _.forEach(leads, (teamLeads, team) => {
        let feedbackLeads = _.filter(teamLeads, (lead) => {
          return lead ? _.includes(_.map(this.getLeadFeedbacks, 'id'), lead.actionType) : false;
        });
        let teamTableRow = {
          'name': team,
          'total': feedbackLeads.length
        };
        let teamData = _.groupBy(feedbackLeads, 'actionType');
        _.forEach(teamData, (groupLeads, actionType) => {
          if (_.includes(_.map(this.getLeadFeedbacks, 'id'), actionType)) {
            teamTableRow[actionType] = groupLeads.length;
          }
        });
        initTableData.push(teamTableRow);
      });
      return initTableData;
    },
    async updateTeamTableData() {
      if (this.jobType !== 'User') {
        let teamData = this.jobType === 'Manager' ? _.groupBy(this._getLeads, 'supervisor') : _.groupBy(this._getLeads, 'owner');
        this.teamTableData = this.getTableLeadStats(teamData);
        if (this.teamTable) {
          this.teamTable.clear();
          this.teamTable.rows.add(this.teamTableData);
          this.teamTable.draw();
        }
        this.updateTeamChartData();
      }
      this.updateHourlyChartData();
    },
    async updateTeamChartData() {
      let feedbackDatasets = {};
      let allFeedback = this.getLeadFeedbacks;
      let selectedFeedback = $('.feedback-active-column');
      if (selectedFeedback.length > 0) {
        allFeedback = _.filter(this.getLeadFeedbacks, (feedback) => {
          return _.includes(_.map($('.feedback-active-column'), 'id'), feedback.id);
        });
      }
      _.forEach(allFeedback, (feedback) => {
        feedbackDatasets[feedback.id] = {
          label: feedback.name,
          data: [],
          borderColor: this.feedbackColours[feedback.id].borderColor,
          borderWidth: 2,
          backgroundColor: this.feedbackColours[feedback.id].backgroundColor
        };
      });
      let teamLabels = [];
      let initTeamChartData = this.teamTableData;
      let selectedRows = this.teamTable.rows('.team-active-row').data();
      if (selectedRows.length > 0) {
        let teams = _.map(selectedRows, 'name');
        let teamLeads = _.groupBy(_.filter(this._getLeads, (lead) => {
          return lead && _.includes(_.map(allFeedback, 'id'), lead.actionType)
              && ((this.jobType === 'Manager' && _.includes(teams, lead.supervisor))
                  || (this.jobType === 'Supervisor' && _.includes(teams, lead.owner)));
        }), 'owner');
        initTeamChartData = this.getTableLeadStats(teamLeads);
      }
      _.forEach(initTeamChartData, (teamStats) => {
        let team = this.prettyPrint(teamStats.name, 'User');
        teamLabels.push(team);
        _.forEach(allFeedback, (feedback) => {
          if (teamStats[feedback.id]) {
            feedbackDatasets[feedback.id].data.push({
              x: team,
              y: teamStats[feedback.id],
              stack: feedback
            });
          } else {
            feedbackDatasets[feedback.id].data.push({
              x: team,
              y: 0,
              stack: feedback.name
            });
          }
        });
      });
      this.teamChartData = {
        labels: teamLabels,
        datasets: _.values(feedbackDatasets)
      };
      this.teamChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
            distribution: 'series',
            offset: true
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }]
        },
        plugins: {
          datalabels: {
            color: '#fff',
            formatter: function (value) {
              if (value.y && value.y > 0) {
                return value.y;
              } else {
                return null;
              }
            }
          }
        }
      }
    },
    async updateHourlyChartData() {
      let rangeSize = moment().diff(moment().startOf('day'), 'hours');
      if (rangeSize > 0) {
        let feedbackDatasets = {};
        let datasets = {};
        let allFeedback = this.getLeadFeedbacks;
        let selectedFeedback = $('.feedback-active-column');
        if (selectedFeedback.length > 0) {
          allFeedback = _.filter(this.getLeadFeedbacks, (feedback) => {
            return _.includes(_.map($('.feedback-active-column'), 'id'), feedback.id);
          });
        }
        _.forEach(allFeedback, (feedback) => {
          feedbackDatasets[feedback.id] = {
            label: feedback.name,
            data: [],
            borderColor: this.feedbackColours[feedback.id].borderColor,
            borderWidth: 2,
            backgroundColor: this.feedbackColours[feedback.id].backgroundColor
          };
        });

        let initHourlyChartData = this._getLeads;
        if (this.jobType !== 'User') {
          let selectedRows = this.teamTable.rows('.team-active-row').data();
          if (selectedRows.length > 0) {
            let teams = _.map(selectedRows, 'name');
            initHourlyChartData = _.filter(this._getLeads, (lead) => {
              return lead && _.includes(_.map(allFeedback, 'id'), lead.actionType)
                  && ((this.jobType === 'Manager' && _.includes(teams, lead.supervisor))
                      || (this.jobType === 'Supervisor' && _.includes(teams, lead.owner)));
            });
          }
        }

        for (let index = 0; index < rangeSize + 1; index++) {
          let xValue = moment().startOf('day').add(index, 'hours').startOf('hour').format('h A');
          let startEpoch = moment().startOf('day').add(index, 'hours').startOf('hour').format('X');
          let endEpoch = moment().startOf('day').add(index, 'hours').endOf('hour').format('X');
          let leads = _.groupBy(_.filter(initHourlyChartData, (lead) => {
            return lead && lead.actionEpoch >= startEpoch && lead.actionEpoch <= endEpoch && lead.actionType !== 'ASSIGNED';
          }), this.jobType === 'Manager' ? 'supervisor' : 'owner');
          let dateRangeData = this.getTableLeadStats(leads);
          _.forEach(dateRangeData, (teamStats) => {
            if (!datasets[teamStats.name]) {
              datasets[teamStats.name] = {}
            }
            _.forEach(allFeedback, (feedback) => {
              if (!datasets[teamStats.name][feedback.id]) {
                datasets[teamStats.name][feedback.id] = _.cloneDeep(feedbackDatasets[feedback.id]);
                datasets[teamStats.name][feedback.id].stack = teamStats.name;
              }
              if (teamStats[feedback.id]) {
                datasets[teamStats.name][feedback.id].data.push({
                  x: xValue,
                  y: teamStats[feedback.id],
                  stack: feedback.name
                });
              } else {
                datasets[teamStats.name][feedback.id].data.push({
                  x: xValue,
                  y: 0,
                  stack: feedback.name
                });
              }
            });
          });
        }

        let chartDatasets = _.flattenDeep(_.map(datasets, (dataset) => {
          return _.values(dataset);
        }));
        this.hourlyChartData = {
          datasets: chartDatasets
        };
        this.hourlyChartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: this.jobType === 'User'
          },
          scales: {
            xAxes: [{
              stacked: true,
              title: 'time',
              type: 'time',
              distribution: 'linear',
              time: {
                parser: 'h A',
                stepSize: 1,
                unit: 'hour'
              },
              offset: true
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                beginAtZero: true
              }
            }]
          },
          plugins: {
            datalabels: {
              color: '#fff',
              formatter: function (value) {
                if (value.y && value.y > 0) {
                  return value.y;
                } else {
                  return null;
                }
              }
            }
          }
        };
        this.enableHourlyChart = chartDatasets.length > 0;
      } else {
        this.enableHourlyChart = false;
      }
    }
  }
}
</script>

<style scoped>

</style>